.new-guest-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 20px;
}

.new-guest-title {
    text-align: center;
    font-family: var(--font-family);
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: auto;
    width: 70%;
    letter-spacing: normal;
    margin-bottom: 30px;
}

.available-tickets-container {
    /* width: 100%; */
    padding: 20px 20px;
    border: 1px solid var(--general-borders);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    transition: all 0.3s ease;
}


.available-tickets-container:hover {
    background-color: var(--secondary-color);
}

.new-guest-label,
.new-guest-available {
    text-align: left;
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    margin-right: 20px;
    /* width: 100%; */
    letter-spacing: normal;
}

.new-guest-available {
    font-size: 20px;
    font-weight: 700;
    margin: 0
        /* width; */
}

.new-guest-form-container {
    margin-top: 30px;
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.new-guest-add-tickets-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

#new-guest-less-button,
#new-guest-add-button {
    background-color: var(--primary-color);
    border: 1px solid var(--general-borders);
    transition: all 0.3s ease;
    height: 40px;
    width: 40px;
    color: var(--icon-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

#new-guest-less-button:hover,
#new-guest-add-button:hover {
    background-color: var(--secondary-color);
    color: #000;
}

#new-guest-less-button {
    border-radius: 15px 0 0 15px;
    border-right: none;
}

#new-guest-add-button {
    border-radius: 0 15px 15px 0;
    border-left: none;
}

.add-guest-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

#add-new-guest-button {
    background: var(--accent-color);
    border-radius: 10px;
    width: 120px;
    height: 40px;
    color: #000;
    border: none;
    font-family: var(--font-family);
    font-weight: 700;
    transition: all 0.3s ease;
}

#add-new-guest-button:hover {
    background-color: var(--accent-focus);
}

.new-guest-nex-step-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    text-align: center;
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
}

#ng-step-button-secondary,
#ng-step-button {
    margin: 10px 5px;
    width: 180px;
    border-radius: 10px;
    font-family: var(--font-family);
    font-weight: 600;
    height: 40px;
    border: none;
    transition: all 0.3s ease;
}

#ng-step-button-secondary {
    border: 1px solid var(--general-borders);
    background-color: var(--primary-color);
    color: var(--icon-color);
}

#ng-step-button {
    background-color: var(--accent-color);
    border: none;
    color: var(--icon-color);
}

#ng-step-button-secondary:hover {
    background-color: var(--secondary-color);
}

#ng-step-button:hover {
    background-color: var(--accent-focus);

}