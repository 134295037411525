.itinerary-cards-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    /* padding: 0px 24px; */

}


.card-container,
.card-container-dev {
    width: 100%;
    padding: 10px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}


.card-container-active {
    justify-content: center;
    flex-direction: column;
}

.card-icon-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.card-icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 70px;
    width: 70px;
}

.card-content {
    flex: 5;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    line-height: 1.1;
    gap: 4px;
    /* padding: 0px 20px; */
}

.card-title,
.card-title-dev {
    margin: 0;
    width: 100%;
    text-align: left;
    line-height: 1.2;
    font-weight: 800;
    font-size: 1.1em;
}

.card-time,
.card-time-dev,
.card-subname,
.card-subname-dev {
    margin: 0;
    width: 100%;
    text-align: left;
    line-height: 1.2;
    font-size: 0.9em;
}

.card-time,
.card-time-dev {
    line-height: 1.4;
    font-size: 1em;
}



.custom-card-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* padding: 10px; */
    position: relative;
    gap: 8px;
}

.custom-card-button {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-card-title,
.custom-card-title-dev,
.custom-card-time,
.custom-card-time-dev,
.custom-card-subname,
.custom-card-subname-dev {
    width: 97%;
    text-align: left;
    /* margin: 8px 0; */
}

.custom-card-time,
.custom-card-time-dev {
    line-height: 0.8;
    margin: 0;
}

/* .custom-card-subname,
.custom-card-subname-dev {
    margin: 8px 0;
} */

.custom-card-address,
.custom-card-address-dev {
    width: 97%;
    text-align: left;
}

.custom-card-subitems {
    width: 85%;
    border-left: 3px solid;
    height: auto;
    /* margin-top: 12px; */
}

.custom-card-subitem {
    width: 100%;
    position: relative;
    margin-top: 12px;
}

.custom-card-subitem-bullet {
    height: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    position: absolute;
    left: -6px;
}

.custom-card-subitem-title,
.custom-card-subitem-time,
.custom-card-subitem-description {
    text-align: left;
    margin-left: 15px;
}

.custom-card-subitem-title {
    font-weight: 600;
}

.custom-card-row {
    width: 97%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
}

.custom-card-row-column {
    flex-direction: column;
}

.custom-card-weather {
    width: 100%;
    border-radius: 15px;
    background-color: transparent;
    overflow: hidden;
    margin-bottom: 10px;
}

.custom-card-map,
.custom-card-map-dev {
    height: 250px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E5E5E5;
}

.custom-card-link {
    width: 97%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 5% 0 0 0; */
}

.custom-card-link-button,
.custom-card-link-button-dev {
    width: 200px;
    height: 40px;
    /* margin-bottom: 3%; */
    border-radius: 30px;
    font-size: 1em;
    font-weight: 600;
    border: none;
}

.spotify-widget {
    width: 100%;
    height: 150px;

}



@media (min-width: 751px) {
    .itinerary-title {
        font-size: 32px;
    }

    .card-container {
        border-radius: 25px;

    }

    .card-title {

        font-size: 1.5em;
    }

    .card-time,
    .card-subname {

        font-size: 1.2em;
    }

    .custom-card-title {
        font-size: 24px;
        font-weight: 600;
    }

    .custom-card-time,
    .custom-card-subname,
    .custom-card-address {
        font-size: 18px;
    }

    .custom-card-map {
        border-radius: 20px;
        height: 400px;
    }

    .custom-card-link-button {
        width: 250px;
        height: 50px;
        margin-bottom: 20px;
        border-radius: 30px;
        font-size: 18px;
        font-weight: 700;
        border: none;
    }
}


@media (min-width: 900px) {

    .card-container {
        width: 60%;
        /* height: 100px; */
    }


}