#cta-primary-button {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    background-color: var(--accent-color);
    color: var(--icon-color);
    font-weight: 600;
    border: 1px solid var(--accent-color);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
}

#cta-primary-button:hover {
    /* border: 1px solid var(--accent-color); */
    /* color: var(--icon-color); */
    background-color: var(--accent-focus);
}

#cta-secondary-button {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-weight: 200;
    border: 1px solid var(--general-borders);
    color: #000;
    font-weight: 600;
}