.gallery-scroll-invitation {
    width: 100%;
    overflow: auto;
    display: flex;
    background-color: transparent;
    gap: 12px;
}

.gallery-scroll-invitation::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}


.gallery-items-inner-container,
.gallery-items-inner-container-dev {
    height: 470px;
    width: 320px;
    overflow: hidden;
    border-radius: 6px;
}

.gallery-items-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media (min-width: 751px) {


    .gallery-items-inner-container {
        height: 550px;
        width: 300px;
    }



}