.dresscode-colors {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.dresscode-color {
    height: 40px;
    width: 40px;
    border-radius: 8px;
}

.dresscode-scroll-container {
    max-width: 100%;
    height: 480px;
    display: flex;
    overflow-x: auto;

}

.dresscode-scroll-container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}



.dresscode-image-container,
.dresscode-image-container-dev {
    width: 280px;
    height: 470px;
    margin: 0 10px;
    border-radius: 6px;
    flex-shrink: 0;
    overflow: hidden;
}

.dresscode-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dresscode-links,
.dresscode-links-dev {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
}

.dresscode-link {
    width: 100%;
}

.dresscode-link-button,
.dresscode-link-button-dev {
    width: 50%;
    height: 40px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    border: none;
}



@media (min-width: 751px) {
    .dresscode-title {
        font-size: 32px;
    }

    .dresscode-description {
        font-size: 24px;
    }

    .dresscode-scroll-container {
        height: 500px;
    }

    .dresscode-image-container {
        width: 250px;
        height: 480px;
    }

    .dresscode-links {
        max-width: 1450px;
        margin: 0;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }


    .dresscode-link-button {
        width: 180px;
        height: 40px;
        font-size: 16px;
        font-weight: 700;

    }


}