.header-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    height: 80px;

    background-color: var(--primary-color);
    border-bottom: 1px solid var(--general-borders);
}

.footer-main-container {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: fixed;
    z-index: 99;
    bottom: 10px;
    border-radius: 12px;
    width: auto;
    padding: 5px;
    height: 60px;
    /* transition: all 0.3s ease; */
    background-color: var(--primary-color);
    border: 1px solid var(--general-borders);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    display: none;
}


.header-container {
    flex: 1;
    /* max-width: 1400px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    padding: 0px 50px;
    max-width: 1480px;
}

.nav-items-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 8px;

}

.nav-items-item {
    width: 150px;
    height: 50px;
    background-color: #ABA9AA70;
    backdrop-filter: blur(10px);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 6px;
    color: var(--icon-color);
    cursor: pointer;
    transition: all 0.3s ease;
}

.nav-items-item:hover {
    background-color: var(--focus-color);
}

.nav-items-item svg {
    font-size: 20px;
}

.nav-items-item span {
    font-size: 16px;
    font-weight: 600;
    font-size: var(--font-family);
}

.nav-items-item-active {
    background-color: var(--accent-color);
}

.nav-items-item-active:hover {
    background-color: var(--accent-focus);
}

.header-main-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    background-color: rgba(245, 243, 242, 0.5);
    backdrop-filter: blur(10px);
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    color: var(--icon-color);
}

.header-main-menu-button:hover {
    background-color: rgba(245, 243, 242, 0.9);
}

.header-main-menu-button:hover svg {
    transform: rotate(180deg);
}

.header-item,
.header-item-shared {
    width: 100px;
    height: 30px;
    border-radius: 50px;
    margin: 0px 5px;
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 15px;
    font-weight: 700;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header-item-shared:hover {
    background-color: var(--general-borders);
}

.header-item:hover {
    background-color: var(--focus-color);
}

.hi--selected {
    background-color: var(--accent-color);
}

.hi--selected-shared {
    background-color: #B1B1B1;
    /* color: #FFF; */
}

.hi--selected:hover {
    background-color: var(--accent-focus);
}

#on-logged-circle,
#on-logged-admin {

    height: 45px;
    aspect-ratio: 1;
    border-radius: 8px;
    background: var(--icon-color);
    font-size: 28px;
    font-family: var(--font-family);
    font-weight: 500;
    color: var(--primary-color);
    border: none;
    transition: all 0.3s ease;

}

#contact-us {
    background: #000;
    transition: 0.5s;
    background-size: 200% auto;

    height: 45px;
    border-radius: 8px;
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--primary-color);
    border: none;
    transition: all 0.3s ease;
}


#on-logged-admin {
    background-color: tomato;
}

#on-logged-admin:hover {
    opacity: 0.8;
}

#on-logged-circle:hover {
    background-color: var(--accent-color);
    color: #000;
}


.nav-item-label {
    margin: 0;
    font-size: 10px;
    font-family: var(--font-family);
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.nav-item-icon {
    margin: 0;
    font-size: 25px;
}

.nav-item-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    padding-top: 5px;
    color: var(--icon-color);
    cursor: pointer;
    transition: all 0.3s ease;
}

.--selected {
    color: var(--icon-color);
    background-color: var(--secondary-color);
}

.nav-item-col:hover {
    background-color: var(--secondary-color);
}

#iniciar-sesion-button {
    height: 45px;
    padding: 0px 20px;
    border-radius: 8px;
    background-color: var(--accent-color);
    color: #000;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    border: none;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
}


#iniciar-sesion-button:hover {
    background: var(--accent-focus);
}

#contact-us:hover {
    background: var(--accent-color);
    color: var(--icon-color);
}

.mobile-opt {
    display: none;
}


.distributor-card {
    position: absolute;
    width: 500px;
    top: 70px;
    right: 0px;
    background-color: var(--primary-color);
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
}

.distributor-card-title {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 18px;
    font-weight: 600;
    width: auto;
    text-align: left;
}

.dist-img-btn-cnt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.upload-btn-ctn {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.distributor-card-image-container {
    width: 80%;
    height: 180px;
    border: 1px solid var(--general-borders);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.dist-card-col-label {
    width: 47%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

@media (max-width: 750px) {

    #iniciar-sesion-button {
        height: 35px;
    }

    .header-main-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        position: fixed;
        z-index: 99;
        top: 0;
        width: 100%;
        padding: 0 25px;
        height: 50px;
        background-color: var(--primary-color);

        /* border-bottom: 1px solid var(--general-borders); */
    }

    .header-container,
    .web-opt {
        display: none;
    }

    .mobile-opt {
        display: flex;
    }

    .footer-main-container {
        display: flex;
    }

    #on-logged-circle {

        height: 30px;
        width: 30px;
        border-radius: 6px;
        background: var(--icon-color);
        font-size: 18px;
        font-family: var(--font-family);
        font-weight: 600;
        letter-spacing: 1px;
        color: var(--primary-color);
        border: none;
        margin-left: 10px;
        transition: all 0.3s ease;

    }


    .nav-item-icon {
        margin: 0;
        font-size: 25px;
    }

    .nav-item-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 8px;
        font-size: 18px;
        color: var(--icon-color);
        background-color: transparent;
    }

    .nav-item-col:hover {
        background-color: transparent;
    }

    .--selected {
        color: var(--icon-color);
        background-color: transparent;
    }

}