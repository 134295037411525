.gm-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    z-index: 3;
    /* border: 1px solid red; */
}

.g-module-info-container {
    width: 100%;
    max-width: 1450px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* padding: 32px; */
    gap: 32px;
    text-align: center;
    line-height: 1.5;
}

.quote-element {
    justify-content: center;
}

.g-module-title,
.g-module-title-dev {
    font-size: 22px;
    font-weight: 600;
}


.g-mdoule-regular-text,
.g-mdoule-regular-text-dev {
    font-size: 16px;
    font-weight: 500;
}


.g-mdoule-light-text,
.g-mdoule-light-text {
    font-size: 14px;
    font-weight: 400;
}

.g-module-items-single-col {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px
}

.quote-element-full,
.quote-element-parcial {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 600;
    padding: 56px 0px;
}



@media (min-width: 900px) {
    .g-module-title {
        font-size: 24px;
    }

    .g-mdoule-regular-text {
        font-size: 18px;
    }

    .g-module-info-container {
        max-width: 950px;

    }

    /* .quote-element-parcial {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

    } */

}