.inv-title-cta-filter--noLoggedd {
    width: 100%;
    max-width: 1450px;
    /* margin-top: 50px; */
    padding-left: 20px;
    margin: 0;
    /* margin-left: 50px; */
}

.discover-main-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 100px;
    max-width: 1450px;
    padding-top: 50px;
}


.not-logged-head {
    font-family: var(--font-family);
    color: var(--icon-color);
    text-align: center;
    width: 100%;
    max-width: 1450px;
    font-size: 64px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 50px;
    letter-spacing: 0px;
    line-height: 1.2;
}

.long-text-head-nl {
    width: 80%;
}

.accent-head {
    color: var(--accent-color);
}

.test-build-invitation-container {
    width: 100%;
    /* border: 1px solid red; */
    margin-top: 30px;
    border-radius: 20px;
    background-color: var(--secondary-color);
    border: 1px solid var(--general-borders);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 40px;
}

.module-image-example {
    width: 100%;
}

.try-inv-head,
.try-inv-single,
.try-inv-single-second,
.try-inv-head-second {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 500px;
    text-align: left;
    width: 100%;
    margin: 0;
}

.try-inv-head,
.try-inv-head-second {
    font-weight: 700;
}

.try-inv-single-second,
.try-inv-head-second {
    font-size: 18px;
}

.try-inv-icon-cont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 32px;
    margin-bottom: 10px;
    color: var(--accent-color);
}


.test-build-invitation-second-container {
    width: 80%;
    border-radius: 15px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    margin-top: 50px;
    background-color: var(--primary-color);
    border: 1px solid var(--general-borders);
    position: relative;
    padding-top: 20px;
    overflow: hidden;
}

.try-dots-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: absolute;
    top: 15px;
    left: 25px;
    z-index: 3;
    /* transform: translateX(-50%); */
}

.try-dot {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background-color: #DDDCDB;
    margin-right: 5px;
}

.accent-dot {
    background-color: var(--accent-color);
}

.watching-image {
    height: 140px;
    position: absolute;
    right: 20px;
    top: -130px
}

.try-inv-buttons-container {
    margin-top: 50px;
    margin-bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}

#try-categorie-button {
    padding: 10px 25px;
    margin: 0px 5px;
    border-radius: 10px;
    font-size: 14px;
    background-color: var(--secondary-color);
    border: 1px solid #DDDCDB;
    font-family: var(--font-family);
    color: var(--icon-color);
}

.try-inv-second-section {
    margin-top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    /* height: 200px; */
}

#access-button {
    display: flex;
    border: none;
    border-radius: 8px;
    z-index: 1;
    letter-spacing: 1px;
    font-size: 18px;
    padding: 25px 35px;
    font-weight: 600;
    background-color: var(--accent-color);
    color: var(--icon-color);
    font-family: var(--font-family);
    /* margin-bottom: 120px; */
}

#access-button:hover {
    background-color: var(--accent-focus);
}


.test-build-small-card {
    flex: 1;
    padding: 30px;
    position: relative;
    height: 450px;
    margin-right: 30px;
    overflow: hidden;
    min-width: 350px;
}

.test-build-small-card:last-of-type {
    margin-right: 0;
}

.decoration-image-yellow,
.decoration-image-admin {
    width: 50%;
    min-width: 400px;
    /* margin-top: 150px; */
}

.decoration-image-admin {
    margin-top: 150px;
}

.decoration-image-create {
    width: 20%;
    min-width: 200px;
    margin-top: 150px;
}

.decoration-yellow-mobile {
    width: 100%;
    max-width: 450px;
    /* min-width: 400px; */
    margin: 0px 0px;
    margin-bottom: -30px;
    margin-top: 30px;
    display: none;
}

.simulator-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 150px;
}

.text-aling-head {
    text-align: center;
    margin-top: 10px;
}

.disable-icon {
    display: flex;
}

.see-invitation-circle-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 150px;
    padding: 50px 50px;
    background-color: var(--secondary-color);
    border-radius: 12px;
    /* width: 100%; */
    border: 1px solid var(--general-borders);
}

.scroll-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid; */
    position: absolute;
    bottom: 50px;
    right: 150px;
    z-index: 5;
}

.downArrow {
    color: var(--primary-color);
    animation: ani 0.8s infinite ease-in-out;
    animation-delay: calc(0.5s * var(--i));
}

@keyframes ani {
    0% {
        opacity: 0;
        transform: scale(0.5) translateY(-5px);
    }

    50% {
        opacity: 1;
        transform: scale(1) translateY(20px);
    }

    100% {
        opacity: 0;
        transform: scale(0.5) translateY(40px);
    }
}


@media (max-width: 1280px) {

    .test-build-small-card {
        margin-right: 0px;
    }

    .test-build-small-card:first-of-type {
        margin-right: 30px;
    }

}


@media (max-width: 1100px) {

    .watching-image {
        display: none;
    }

    .not-logged-head {
        text-align: center;
    }
}


@media (max-width: 1150px) {

    .try-inv-head {
        font-size: 16px;
    }

    .try-inv-single {
        font-size: 14px;
    }


    .test-build-invitation-second-container {
        width: 100%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.10);
    }

    .try-dot {
        height: 8px;
        width: 8px;
    }

    #try-categorie-button {
        padding: 10px 25px;
        border-radius: 8px;
        font-size: 12px;
    }

    .simulator-container {
        display: none;
    }

    #access-button {

        margin-bottom: -20px;
    }



}

@media (max-width: 930px) {

    .try-inv-second-section {
        margin-top: 0px;
        width: 100%;
        height: 1200px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }

    .test-build-small-card {
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
    }



}




@media (max-width: 750px) {

    .long-text-head-nl {
        width: 100%;
    }

    .decoration-image-yellow {
        margin: 0px 0px;
        min-width: 300px;
    }

    .decoration-image-admin {
        margin-top: 100px;
        min-width: 300px;

    }

    .decoration-image-create,
    .see-invitation-circle-cont {
        margin-top: 100px;
    }

    .discover-main-container {
        padding: 40px;
        padding-top: 0px;
        padding-bottom: 120px;
    }

    .first-box {
        width: 100%;
        margin-top: 30px;
        border-radius: 0px;
        background-color: transparent;
        border: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0px;
    }

    .not-logged-head {
        font-size: 38px;
        margin-bottom: -10px;
        margin-top: 20px;
        line-height: 1.1;
        text-align: left;
        width: 100%;
        font-weight: 600;
    }


    .disable-icon {
        display: none;
    }

    .try-inv-head {
        font-size: 20px;
        line-height: 1.4;
        text-align: left;
        font-weight: 500;
        /* border: 1px solid red; */
    }

    .text-aling-head {
        text-align: left;
        margin-top: 40px;
        /* border: 1px solid red; */
    }

    .discover-description {
        display: none;
    }

    .try-inv-buttons-container {
        width: 100%;
        margin-top: 20px;
        margin-bottom: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        display: none;
        /* gap: 10px;
        border: 1px solid red; */
    }

    #try-categorie-button {
        padding: 0px 5px;
        border-radius: 5px;
        font-size: 10px;
        /* border: 1px solid red; */
    }

    .test-build-invitation-second-container {
        border-radius: 8px;
        height: 330px;
        margin-top: 20px;
        background-color: var(--primary-color);
        border: 1px solid var(--general-borders);
        position: relative;
        padding-top: 20px;
        padding: 5px;
        overflow: hidden;
        /* border: 1px solid red; */
    }

    .test-build-invitation-second-container img {
        /* height: 100%; */
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* top: 0;
        left: -50%; */
        /* border: 1px solid red; */
        /* transform: translateX(-50%); */

    }

    .try-inv-second-section {
        margin-top: 0px;
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        overflow: hidden;
        /* border: 1px solid red; */
    }

    .test-build-small-card {
        padding: 10px;
        position: relative;
        height: auto;
        margin-right: 0px;
        overflow: hidden;
        min-width: 150px;
        border-radius: 10px;
        padding-bottom: 220px;
        margin-bottom: 20px;
        /* border: 1px solid red; */
    }


    .try-inv-single {
        font-size: 18px;
        line-height: 1.2;
        text-align: justify;
        margin-top: 10px;
        /* border: 1px solid red; */
    }

    .try-inv-guests-box {
        height: 230px;
        margin-top: 20px;
        /* border: 1px solid red; */
    }




    .try-inv-icon-cont {
        font-size: 24px;
    }


    #access-button {
        font-size: 16px;
        padding: 23px 20px;
        /* margin-bottom: 120px; */
    }


}