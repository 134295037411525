.new-invitation-header {
    width: 95%;
    padding: 0px 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

}

.new-invitation--title {
    margin: 0;
    padding: 0;
    width: auto;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    font-family: var(--font-family);
}

.available-label {
    color: var(--accent-color);
}


.steps-content-container {
    margin-top: 70px;
    padding: 0px 20px;
}

.steps-content-container .ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: var(--accent-color);
}

.steps-content-container .ant-steps .ant-steps-item-finish .ant-steps-item-icon {

    background-color: var(--focus-color);
    border-color: var(--focus-color);

}

.steps-content-container .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: var(--accent-color);

}

.steps-content-container .ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: var(--accent-color);
    border-color: var(--accent-color);


}

.ant-steps-item-container .ant-steps-item-tail .ant-steps-item-icon .ant-steps-icon {
    color: #000 !important;
}



.steps-content {
    height: 600px;
    /* border: 1px solid red; */
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

}

.steps-buttons-container,
.steps-buttons-container-start {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px 20px;
}

.steps-buttons-container-start {
    justify-content: flex-end;
}

#prev-next-button {
    color: var(--icon-color);
    font-size: 18px;
    font-weight: 600;
    padding: 0px 10px;
    height: 40px;
    border-radius: 10px;
    font-family: var(--font-family);
    display: flex;
    align-items: center;
    justify-content: center;
}

#prev-next-button:hover {
    background-color: var(--secondary-color);
}

#prev-next-button:active {
    color: var(--accent-color);
    background-color: transparent;
}


.new-invitation-dominio-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 55px;
}

.dominio-new-invitation-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 30px 0px;
}

.dominio-state-inactive {
    height: 25px;
    width: 25px;
    font-size: 14px;
    border-radius: 50px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dominio-state-available {
    height: 25px;
    width: 25px;
    font-size: 14px;
    border-radius: 50px;
    background-color: var(--accent-color);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dominio-state-not-available {
    font-size: 18px;
    font-weight: 700;
    font-size: var(--font-family);
    color: rgb(205, 42, 42);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.dominio-state-not-available>*:first-child {
    margin-right: 5px;
}

.new-invitation-label {
    font-size: 18px;
    font-weight: 600;
    width: auto;
    font-family: var(--font-family);
    color: var(--icon-color);
}

.dominio-info-container {
    width: 100%;
    height: 300px;
    /* border: 1px solid var(--general-borders); */
    margin-bottom: 30px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

}

.route-info {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 1.3;
    width: 80%;
    text-align: center;
    color: var(--icon-color);
    margin-top: 30px;
}

.route-image-container {
    /* height: 50px; */
    width: 70%;
    overflow: hidden;
    border-radius: 12px;
    margin-top: 20px;
}

.route-image-container img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.new-inv-templates-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0px;


}

.template-item,
.template-item-selected {
    width: 150px;
    aspect-ratio: 1;
    border: 1px solid var(--general-borders);
    border-radius: 15px;
    margin: 5px 0px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.type-item,
.type-item-selected {
    width: calc(100% - 50px);
    height: 150px;
    border: 1px solid var(--general-borders);
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.plan-item,
.plan-item-selected {
    width: calc(30% - 5px);
    /* height: 350px; */
    border: 1px solid var(--general-borders);
    border-radius: 15px;
    margin: 5px 0px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.template-item-selected,
.type-item-selected,
.plan-item-selected {
    /* border: 2px solid var(--accent-color); */
    background-color: var(--secondary-color);
}

.type-image-container {
    height: 100%;
    flex: 1;
    /* margin-right: 30px; */
    /* margin-bottom: 10px; */
    /* border: 1px solid var(--general-borders); */
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.type-info-container {
    /* height: 35%; */
    flex: 2;
    /* margin-left: 20px; */
    padding-left: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}


.type--title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    color: var(--icon-color);
}

.type--description {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: var(--icon-color);
}

.template-name-label,
.plan-name-label,
.plan-time-label,
.plan-price-label {
    font-size: 14px;
    letter-spacing: 3%;
    line-height: 1;
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--icon-color);
}

.plan-price-label {
    font-size: 18px;
    font-weight: 800;
    margin: 2px 0;
    letter-spacing: 0;
}

.plan-image-container {
    height: 50px;
    aspect-ratio: 1;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 0px solid var(--general-borders);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--focus-color);
    color: var(--accent-color);
}

.plan-time-label {
    margin-bottom: 10px;
}

.plan-features-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /* padding-left: 20px; */
    /* padding: 0px 50px; */
    /* border: 1px solid red; */
}

.benefit-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 7px 0;
    padding-left: 20px;
}

.label-tag {
    transition: all 0.3s ease;
}

.label-tag:hover {
    color: var(--accent-color);
}



#new-inv-choose-plan,
#new-inv-choose-plan--selected {
    margin: 15px 0 10px 0;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    color: var(--icon-color);
    border: 1px solid var(--icon-color);
    width: 90%;
}

#new-inv-choose-plan--selected {
    background-color: var(--accent-color);
    color: var(--icon-color);
    font-weight: 700;
    border: 1px solid var(--accent-color);
}

#new-inv-choose-plan--selected:hover {
    background-color: var(--accent-focus);
}

#new-inv-choose-plan:hover {
    background-color: var(--secondary-color);
}

#new-invitation-create-button {
    border-radius: 8px;
    padding: 20px 35px;
    background: var(--accent-color);
    border: none;
    font-weight: 700;
    font-size: 18px;
    color: var(--icon-color);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

#new-invitation-create-button:hover {
    background-color: var(--accent-focus);
}