.locked-invitation-background {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: linear-gradient(180deg, #87B4A5 0%, #9EA8C6 56.6%, #C5BA96 100%);

}

.locked-invitation-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 1000px;
    /* background-color: #F5F3F2; */
    /* border: 1px solid red; */
    padding: 100px 30px;
}

.locked-icon-container {
    height: 85px;
    width: 85px;
    border-radius: 100px;
    background: rgba(255, 255, 255, .30);
    box-shadow: 0px 0px 12.7px rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 36px;
}

.locked-page-title {
    font-size: 22px;
    font-family: var(--font-family);
    color: #FFF;
    width: auto;
    font-weight: 700;
    margin: 0;
    margin-top: 30px;

}

.locked-page-text {
    font-size: 16px;
    font-family: var(--font-family);
    color: #fff;
    line-height: 159%;
    width: 90%;
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
}

.locked-input {
    width: 230px;
    height: 45px;
    border-radius: 50px;
    border: 2px solid #FFF;
    background-color: transparent;
    color: #FFF;
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
}

.locked-input::placeholder {
    font-style: italic;
    color: #FFFFFF80;
}

.locked-input:focus,
.locked-input:active,
.locked-input:hover {
    background-color: transparent;
    border: 2px solid #FFF;
}

#locked-access-button {
    width: 230px;
    height: 45px;
    margin-top: 20px;
    background-color: #FFF;
    color: #9CA8C4;
    font-weight: 700;
    font-size: 20px;
    transition: all 0.3s ease;
    border: none;
    border-radius: 50px;
}

#locked-access-button:hover {
    opacity: 0.8;
}

.action-button {
    position: fixed;
    display: flex;
    height: 40px;
    width: 180px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    border: none;
    border-radius: 30px;
    z-index: 10;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.85;
    backdrop-filter: blur(10px);
}


.header-invitation {
    position: fixed;
    z-index: 1;
    backdrop-filter: blur(16px);
    height: 80px;
    opacity: 0.95;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-invitation-title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}

#expandedbutton:hover,
#expandedbutton:active,
#expandedbutton:focus {
    border: 1px solid var(--accent-color);
    color: var(--accent-color)
}





@media (min-width: 751px) {

    .action-button {

        height: 50px;
        width: 200px;

        bottom: 50px;

        letter-spacing: 2px;
        font-size: 16px;
        font-weight: 800;
    }


    .header-invitation-title {
        font-weight: 800;
        font-size: 24px;
    }



}