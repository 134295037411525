:root {
  --primary-color: #FFF;
  --secondary-color: #F5F3F2;
  --secondary-focus: #FCFCFC;
  --accent-color: #FFB32D;
  --accent-focus: #F99D00;
  --accent-color-dark: #dea300;
  --font-family: 'Poppins';
  --general-borders: #EBEBEB;
  --focus-color: #FFF5D0;
  --focus-color-light: #f2f2f3;
  --icon-color: #000000;
  --text-color: #000000;
  --brand-gradient: linear-gradient(to top, #FFB32D, #fdc564);
  --error-color: #D32F2F
}



.scroll--container {
  /* border: 1px solid red; */
  width: 97%;
  overflow: auto;
  display: flex;
}

.ant-float-button {
  background-color: #ff0000;
  /* Cambiar el color de fondo a rojo */
}

.btn-grad {
  background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA 51%, #77A1D3 100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}


.interactive--card {
  transition: all 0.35s;
}

.interactive--card:hover {
  transform: translateY(-5px);
}

.custom-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;
}

.custom-button-container:hover .custom-button-icon-container {
  background-color: var(--accent-focus);
}

.custom-button-container:hover .custom-button-text {
  color: var(--accent-focus);
}


.custom-button-icon-container {
  transition: all 0.3s ease;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: var(--accent-color);
  margin-right: 5px;
  color: var(--icon-color);
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.custom-button-text {
  transition: all 0.3s ease;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  color: var(--accent-color);
  margin: 0;
}

.custom-button-secondary {
  background-color: var(--icon-color);
}

.custom-button-text-secondary {
  color: var(--icon-color);
  /* background-color: var(--icon-color); */
}

.responsive-mobile {
  display: none;
}

/* .react-resizable-handle-e {
  background-color: var(--secondary-color);

  font-size: 14px;
} */

/* .inv-background-texture {
  filter: grayscale(1) contrast(1.5) brightness(1.8);

} */

@media (max-width: 750px) {
  .responsive-web {
    display: none;
  }

  .responsive-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}