.content-land-page {
    width: 100%;
    max-width: 1450px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px;
    /* height: 200vh; */
    position: absolute;
    top: 0;
    /* background-image: url('https://picsum.photos/3800/5000');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    /* Centrar la imagen */
}

.land-image-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.land-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1.5s ease;
}

.land-background-container,
.land-background-login {
    height: 100%;
    width: 100%;
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent); */
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    padding: 40px;
    /* z-index: 99; */
}

.land-info-cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.land-info-ct-login {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.land--title {
    font-family: var(--font-family);
    color: var(--text-color);
    font-size: 64px;
    font-weight: 900;
    margin: 0;
    text-align: left;
}

.land--subtitle {
    font-family: var(--font-family);
    color: var(--text-color);
    font-size: 48px;
    font-weight: 400;
    margin: 0;
    margin-top: 20px;
    text-align: left;
}

.land-control-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}

.land-info-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 40px;
}

.land-info-label {
    font-family: var(--font-family);
    color: var(--text-color);
    font-weight: 800;
    font-size: 26px;
    letter-spacing: 0px;
    text-align: left;
}

.trending-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0;
}

.trending-card,
.featured-card,
.options-card {
    width: calc(25% - 20px);
    height: 600px;
    background-color: var(--secondary-color);
    border-radius: 15px;
    margin: 10px 0;
}

.featured-card {
    height: 300px;
}

.options-card {
    width: calc(33.33% - 20px);
    height: 300px;
}

#lande-slider-button,
#lande-slider-button--selected {
    height: 4px;
    width: 60px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    transition: all 0.3s ease;
    margin: 0px 5px;
}

#lande-slider-button:hover {
    background-color: rgba(255, 255, 255, 0.7);
}

#lande-slider-button--selected {
    background-color: #FFF;
}

#land-cta-button {
    background-color: transparent;
    font-family: var(--font-family);
    color: var(--accent-color);
    font-weight: 800;
    padding: 25px 40px;
    border-radius: 50px;
    border: 2px solid var(--accent-color);
    font-size: 22px;
    margin-top: 30px;
    transition: all 0.3s ease;
}

#land-cta-button:hover {
    background: var(--brand-gradient);
    border: none;
    color: #FFF;
    border: 2px solid var(--accent-color);
}