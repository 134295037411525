.tools-main-container {
    /* width: 85px; */
    background-color: transparent;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 4;
    min-height: 650px;
    padding: 0px;
    height: 80vh;
    max-height: 720px;

}

.tools-main-container-onTry {
    width: auto;
    height: 85px;
    flex-direction: row;
    min-width: auto;
    max-width: 90px;
}


.tools-container,
.tools-container-onTry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 65px;
    padding: 5px 0;
    height: 100%;
    transition: all 0.3s ease;
    background-color: var(--secondary-color);
    /* background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color) 51%, var(--secondary-color) 100%); */
    /* border: 1px solid red; */
    border-radius: 50px;
}

.tools-container-onTry {
    flex-direction: row;
    width: auto;
    height: 65px;
    position: absolute;
    padding: 0px 5px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.single-button,
.single-button--selected,
.cta-upload-button,
.cta-upload-button-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 55px;
    border-radius: 50px;
    /* margin-bottom: 15px; */
    cursor: pointer;
    transition: all 0.3 ease;
}

.single-button:last-of-type,
.single-button--selected:last-of-type {
    margin-bottom: 0px;
}

.single-button {
    color: #87878799;
}

.single-button-tag-container:hover .single-button,
.single-button-tag-container:hover .single-button--selected {
    color: var(--accent-color);
}


.single-button--selected {
    background-color: var(--primary-color);
    color: #000;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    /* border: 0.5px solid #d9d9d9; */
}

.single-button-tag-container {
    position: relative;
}

.tag-button-tools:hover+.single-button-name-tag {
    opacity: 1;
}


.single-button-name-tag {
    background-color: var(--accent-color);
    color: #FFF;
    font-weight: 600;
    font-family: var(--font-family);
    padding: 8px 10px;
    border-radius: 0px 8px 8px 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    width: 90px;
    position: absolute;
    right: -80px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease;
    /* z-index: 2; */
}


.cta-upload-button {
    height: 55px;
    width: 55px;
    margin-top: 10px;
    color: var(--primary-color);
    background: var(--brand-gradient);
    border: none;
    /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15); */

}

#cta-upload-button:hover {
    border: 2px solid var(--accent-color);
    color: var(--accent-color);

}


.cta-upload-button-secondary {
    height: 55px;
    width: 55px;
    margin-top: 10px;
    color: var(--icon-color);
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15); */

}


#popup-confirm {
    width: 350px;
}


#popup-confirm .ant-btn-primary {
    background-color: var(--accent-color);
    color: #000;
    border-radius: 5px;
    border: none;
    width: 80px;
    font-weight: 600;
}

#popup-confirm .ant-btn-primary:hover {
    background-color: var(--accent-focus);
}


#popup-confirm .ant-btn-default {
    border: none;
    color: var(--icon-color);
    font-weight: 700;
}

#popup-confirm .ant-btn-default:hover {
    color: var(--accent-color);
}

/* 
#save-changes-button {
    width: 200px;
    height: 40px;
    border-radius: 50px;
    background: var(--brand-gradient);
    color: #FFF;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    border: none;
} */


#save-changes-button,
#save-changes-button-share {
    background-image: linear-gradient(to right, #FFB32D 0%, #FFDE57 51%, #FFB32D 100%);
    transition: 0.5s;
    background-size: 200% auto;
    /* display: block; */
    /* width: 200px; */
    height: 35px;
    border-radius: 10px;
    color: #000000;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 13px;
    border: none;
    padding: 0px 20px;
    /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15); */
}

#save-changes-button-share {
    background-image: linear-gradient(to right, #B1B1B1 0%, #CECECE 51%, #B1B1B1 100%);
    color: #000;
}


#refresh-changes-button,
#refresh-changes-button-active,
#refresh-changes-button-secondary {
    background-color: var(--secondary-color);
    transition: 0.5s;
    background-size: 200% auto;
    /* display: block; */
    /* width: 200px; */
    height: 35px;
    border-radius: 10px;
    color: var(--icon-color);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 12px;
    border: none;
    padding: 0px 10px;
    /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15); */
    border: 1px solid var(--general-borders);
}

#refresh-changes-button-active {
    background-color: none;
}

#refresh-changes-button-secondary {
    background-color: #4d4d4d;
    color: #e7e7e7;
}

#refresh-changes-button-secondary:hover {
    opacity: 0.8;
}

#save-changes-button:hover,
#save-changes-button-share:hover {
    background-position: right center;
}

#refresh-changes-button:hover {
    background-color: var(--secondary-focus);
}

.header-shared-label {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 400;
    color: var(--icon-color);
}

.header-shared-label b {
    color: var(--accent-color);
    font-weight: 800;
}









/* .tools-container :where(.css-dev-only-do-not-override-djtmh8) a {
    color: #FFA700;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
} */