.qr-drawer-container {
    width: 100%;
    height: 100%;
    /* height: 950px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.qr-card-container {
    width: 370px;

    /* min-height: 700px; */
    z-index: 2;
    border-radius: 44px;
    overflow: hidden;
}

.qr-background-cover {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    /* padding-bottom: 50px;
    padding-top: 50px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

}

.qr-action-btns-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 15px;
    width: 350px;
}

.image-card-qr {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 2;
}

.qr-label {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 14px;
}

:root {
    --glow-colors: linear-gradient(132deg, #FFB32D, #fdc564, #FFB32D);
}

.glowing-card {
    position: relative;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: transparent;
    padding: 5px;
    z-index: 1;
    animation: glow-move-circular 2s linear infinite;
}

.glowing-card::after {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: var(--glow-colors);
    border-radius: inherit;
    z-index: -3;
    filter: blur(40px);
    transform: scale(1.1);
    animation: spin 3s linear infinite;
}

.qr-bright-shadow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    z-index: 3;

}


.qr-bright-shadow {
    background-image: linear-gradient(to bottom, #00000010 0%, #FFFFFF30 51%, #00000010 100%)
}

.qr-bright-shadow {
    transition: 0.5s;
    background-size: auto 200%;
    display: block;
    animation: hover-to-bottom 0.5s forwards;
}

.qr-bright-shadow:hover {
    animation: hover-to-top 0.5s forwards;
}

#qr-handler-edit-button,
#qr-handler-share-button,
#qr-handler-share-button-active,
#qr-handler-edit-button-active {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 4;
    border-radius: 40px;
    height: 30px;
    width: 30px;
    background-color: #FFFFFF;
    color: #000;
    border: 1px solid #FFF;
    transition: all 0.3s ease;
}

#qr-handler-share-button,
#qr-handler-share-button-active {
    right: 70px;
    width: auto;
}

#qr-handler-edit-button-active,
#qr-handler-share-button-active {
    background-color: #FFF;
    color: var(--accent-color);
}


#qr-handler-edit-button:hover,
#qr-handler-share-button:hover {
    background-color: #FFFFFF40;
}

.qr-edit-card {
    position: absolute;
    top: 70px;
    right: 20px;
    /* width: 310px; */
    /* max-width: 310px; */
    /* height: 200px; */
    border-radius: 12px;
    background-color: #FFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 15px;
    width: 310px;
    /* padding: 10px; */
}


/* Definir la animación */
@keyframes hover-to-bottom {
    0% {
        background-position: center top;
    }

    100% {
        background-position: center bottom;
    }
}

@keyframes hover-to-top {
    0% {
        background-position: center bottom;
    }

    100% {
        background-position: center top;
    }
}

@keyframes glow-move-circular {
    0% {
        box-shadow: 0 -5px 20px #FFB32D;
    }

    25% {
        box-shadow: 5px 0 20px #fdc564;
    }

    50% {
        box-shadow: 0 5px 20px #FFB32D;
        ;
    }

    75% {
        box-shadow: -5px 0 20px #fdc564;
    }

    100% {
        box-shadow: 0 -5px 20px #FFB32D;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


#qr-action-button,
#qr-action-button-primary {
    /* width: 100%; */
    /* background-color: var(--secondary-color); */
    background-color: #FFF;
    border: 1px solid var(--general-borders);
    font-family: var(--font-family);
    color: var(--icon-color);
    transition: all 0.3s ease;
    padding: 10px 10px;
    flex: 1;
    margin-right: 5px;
    height: 35px;
}

#qr-action-button-primary {
    background-color: var(--secondary-color);
}

#qr-action-button-primary:hover {
    background-color: var(--accent-color);
}

#qr-action-button:last-of-type {
    margin: 0px;
}

#qr-action-button:hover {
    background-color: var(--secondary-color);
}