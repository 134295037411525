.full-logo-container {
    position: relative;
}

.logo-item-calendar {
    position: absolute;
    top: -4px;
    height: 10px;
    width: 2.5px;
    border-radius: 3px;
    background-color: var(--icon-color);
}

.resorte-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 40px;
    padding: 0px 10px;
    position: absolute;
    top: -5px;
}

.logo-res {
    height: 8px;
    width: 4px;
    border-radius: 10px;
    background-color: #ECEFF6;
}

.logo-container,
.logo-container-transparent {
    height: 40px;
    aspect-ratio: 1;
    border-radius: 10px;
    margin-right: 15px;
    /* box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15); */
    /* border: 1px solid var(--general-borders); */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    border: 2px solid #000;
}

.logo-container-transparent {
    border: none;
}

.logo-yellow-section,
.logo-yellow-section--transparent {
    height: 17px;
    width: 100%;
    background: var(--accent-color);
    border-bottom: 2px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-yellow-section--transparent {
    background: rgba(255, 255, 255, 0.25);
}

.logo-gray-section,
.logo-white-section,
.logo-transparent-section {
    height: 20px;
    width: 100%;
    background-color: #fff;
}

.logo-white-section {
    background-color: #FFF;
}

.logo-transparent-section {
    background-color: rgba(255, 255, 255, 0.5);
}

.logo-month {
    font-size: 8px;
    font-weight: 800;
    letter-spacing: 1px;
    margin-top: 2px;
    color: #fff;
    font-family: var(--font-family);
    /* transition: all 0.4s ease; */
}

.logo-day,
.logo-day-white {
    position: absolute;
    bottom: -19px;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    color: var(--icon-color);
    font-family: var(--font-family);
    /* transition: all 0.4s ease; */
}

.logo-day-white {
    color: #FFF;
}

.i-attend-text-logo {
    font-weight: 500;
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 28px;
    margin: 0;
}

/* @media (max-width: 750px) {
    .i-attend-text-logo {
        display: none;
    }
} */