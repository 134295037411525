.module-cover-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1250px;
}


.cover-container,
.cover-container-dev {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    /* margin-bottom: 50px; */
    width: 100%;
    max-width: 1480px;
}

.cover-container img,
.cover-container-dev img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.background-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    opacity: 0.8;
}

.cover--title-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.date-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.date-date,
.date-divider-dev {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 1.3em;
    letter-spacing: 0.1em;
    font-weight: 400;
}

.date-divider,
.date-divider-dev {
    width: 75%;
    margin: 2vh 0 1vh 0;
}

.date-row,
.date-row-dev {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.date-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.date-time {
    font-size: 1.8em;
    margin: 0;
    font-weight: 400;
}

.date-unit {
    margin: 1vh 0 0 0;
    font-weight: 400;
}


@media (min-width: 900px) {
    .cover-container {
        border-radius: 0px 0px 18px 18px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
    }

    .date-divider {
        width: 50%;
    }

    .date-date {
        font-size: 2em;
    }

    .date-row {
        width: 60%;
    }
}