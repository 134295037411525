.pricing-main-container {
    margin-top: 80px;
    width: 100%;
    max-width: 1480px;
    height: auto;
    background-color: var(--primary-color);
    padding: 100px;
    padding-top: 50px;
}

.pricing-title-image-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    /* position: relative; */
    /* padding-bottom: 35px; */
}

.pricing-title-subtext-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    /* padding: 0px 200px; */
    margin-bottom: 70px;
    /* flex-direction: column; */
}

.pricing-title-page {
    font-family: var(--font-family);
    font-size: 64px;
    font-weight: 600;
    margin: 0;
    /* margin-bottom: 10px; */
    letter-spacing: 0;
    width: 70%;
    line-height: 1;
    text-align: left;
    color: var(--icon-color);
}

.pricing-sub-text {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    letter-spacing: 0;
    line-height: 1.2;
    color: #00000060;
    width: 70%;
    text-align: left;
    margin-top: 30px;
}





.pricing-cards-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 120px;
    margin-top: -30px;
    position: relative;
}

.pricing-decoration-image {
    height: 200px;
    position: absolute;
    z-index: 2;
    right: 0px;
    top: -140px;
}

.pricing-plan-card {
    width: calc(33% - 20px);
    height: 380px;
    border-radius: 15px;
    background-color: var(--secondary-color);
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.pricing-card-title-icon-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-size: 22px;
    color: var(--icon-color);
}

.pricing-card-title-icon-container span {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 22px;
    font-weight: 700;
    margin-left: 10px;
    line-height: 1;
}

.pricing-card-description {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-weight: 400;
    font-family: 18px;
    width: 100%;
    text-align: left;
    margin-top: 10px;
}


.pricing-card-time {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 42px;
    margin: 0;
    font-weight: 600;
    width: auto;
    text-align: left;
    margin-top: 10px;
}

.pricing-card-price {
    width: 100%;
    padding: 15px 10px;
    color: var(--primary-color);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: var(--icon-color);
    margin-top: 10px;
    word-wrap: break-word;
    text-align: center;
    line-height: 1;
}

.pricing-features-conainer {
    margin-top: 25px;
    /* width: calc(50% - 10px); */
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 50px;
    /* border: 1px solid red; */
}

.pricing-features-title {
    font-family: var(--font-family);
    color: var(--icon-color);
    font-weight: 600;
    font-size: 22px;
}


.prcing-feature-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 0px;
    width: 100%;
    border-bottom: 1px solid var(--general-borders);
}

.prcing-feature-row:last-of-type {
    border: none;
}

.pricing-feature-text {
    /* margin-left: 15px; */
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 14px;

}

/* .prcing-feature-row .ant-popover .ant-popover-inner .ant-popover-inner-content  */

/* .ant-popover .ant-popover-content .ant-popover-inner-content {
    width: 200px !important;
} */




@media (max-width: 1300px) {

    .pricing-title-subtext-container {

        padding: 0;
        /* flex-direction: column; */
    }

    .pricing-cards-container {
        flex-wrap: wrap;
    }

    .pricing-plan-card {

        width: calc(50% - 10px);
        margin-bottom: 30px;
    }
}



@media (max-width: 1200px) {

    .pricing-decoration-image {
        display: none;
    }

    .pricing-title-page {
        font-size: 42px;
    }


}

@media (max-width: 950px) {

    .pricing-sub-text {

        width: 100%;
    }

    .icon-text {
        display: block;
        /* border: 1px solid red; */
        margin: 0;
    }

    .icon-off-text {
        display: none;
    }

    .pricing-cards-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column-reverse;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
    }

    .pricing-plan-card {

        width: 100%;

    }
}

@media (max-width: 750px) {

    .pricing-title-subtext-container {
        margin-bottom: 30px;
    }

    .pricing-main-container {
        padding: 30px;
    }

    .pricing-sub-text {
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;

    }

    .pricing-title-page {
        font-size: 36px;
        line-height: 1;
        text-align: left;
        width: 100%;
    }

    .features-title {
        margin: 50px 0;
    }

    .pricing-hidde-item {
        display: none;
    }
}


/* .pricing-card-time {
    background-image: linear-gradient(to right, #FFB32D 0%, #FFDE57 51%, #FFB32D 100%);
    transition: 0.5s;
    background-size: 200% auto;
    font-family: var(--font-family);
    color: var(--icon-color);
    font-size: 64px;
    margin: 0;
    font-weight: 700;
    width: 100%;
    text-align: left;


}

.pricing-card-time:hover {
    background-position: right center;
} */